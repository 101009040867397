import React from 'react'
import Auth from './Auth'

export default function SignIn() {
  return (
    <Auth>
        Sign in
    </Auth>
  )
}
